<template>
    <div class="right">
        <div class="optblock">
            <!-- 操作区域 -->
            <div class="buttonstyle">
                <el-button class="add" @click="getAddPage()">添加文章</el-button>
            </div>
            <!-- <el-button type="success">成功按钮</el-button> -->
        </div>
        <div class="datablock">
            <el-table :data="articles" border style="width: 100%;height: 100%;">
                <el-table-column prop="id" label="id" width="260">
                </el-table-column>
                <el-table-column prop="title" label="标题" width="250">
                </el-table-column>
                <el-table-column prop="description" label="描述" width="250">
                </el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button @click="view(scope.row.id)" type="text" size="small">查看</el-button>
                        <el-button @click="edit(scope.row.id)" type="text" size="small">编辑</el-button>
                        <el-button @click="del(scope.row.id)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagemain">
            <div class="pagesub">
                <!--分页-->
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'ManageArticle',
    data() {
        return {
            pageNum: 1,
            pageSize: 10,
            currentPage: 1,
            total: 0,
            articles: []
        }
    },
    created() {
        //请求接口，获取详情
        axios.get('/lspace/bgArticle/getPageList',
            {
                params: {
                    pageNo: 1,
                    pageSize: 10
                }
            })
            .then(response => {
                // 处理响应
                console.log(response.data);
                this.total = response.data.total;
                this.articles = response.data.records;
                // this.article = response.data.data;
                // this.$message({
                //     message: '提交成功',
                //     type: 'success'
                // });
            })
            .catch(error => {
                // 处理错误
                console.error(error);
                this.$message.error('获取详情失败');
            });
    },
    methods: {
        view(id) {
            this.$router.push({
                name: 'detail',
                query: {
                    articleid: id
                }
            })
        },
        edit(id) {
            this.$router.push({
                name: 'writeArticle',
                query: {
                    articleid: id
                }
            })
        },
        del(id) {
            this.$confirm('是否删除文章?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const formData = new FormData();
                formData.append('id', id);
                axios.post('/lspace/bgArticle/del', formData)
                    .then(response => {
                        // 处理响应
                        console.log(response.data);
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        location.reload();
                    })
                    .catch(error => {
                        // 处理错误
                        console.error(error);
                        this.$message.error('删除失败');
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        getAddPage() {
            this.$router.push({
                name: 'writeArticle'
            })
        },
        //监听 pageSize 改变事件
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getData()
        },
        //监听页码改变的事件
        handleCurrentChange(newPage) {
            this.pageNum = newPage
            this.getData()
        }
    }
}
</script>

<style scoped>
.right {
    width: 78%;
    /* height: 100%; */
    background-color: #FFFFFF;
    /* background-color:cadetblue; */
    float: left;
    margin-left: 1%;
}

a {
    text-decoration: none;
    /* color: #fff; */
}

.router-link-active {
    text-decoration: none;
}


.pagemain {
    width: 100%;
    height: 80px;
    /* background-color: aqua; */
}

.pagesub {
    width: 40%;
    height: 50px;
    /* background-color: burlywood; */
    margin-left: 57%;
    margin-top: 15px;
    float: left;
}

.el-pagination {
    width: 100%;
    height: 100%;
}

.optblock {
    width: 100%;
    height: 55px;
    /* background-color: cadetblue; */
}

.datablock {
    width: 100%;
    height: 600px;
    /* background-color: burlywood; */
}

.buttonstyle {
    width: 100px;
    height: 40px;
    /* background-color: brown; */
    margin-top: 15px;
    margin-left: 10px;
}

.add {
    width: 100%;
    height: 100%;
}
</style>