<template>
    <div class="header">
    </div>
</template>

<script>

export default {
    name: 'HeaderView'
}
</script>

<style scoped>
.header {
    height: 3px;
    background-color:#222222;
}

</style>