import Vue from 'vue'
import VueRouter from 'vue-router'
import ArticleList from '../components/ArticleList.vue'
import ArticleDetail from '../components/ArticleDetail.vue'
import HomeView from '../views/HomePage.vue'
import ManageArticle from '../views/ManageArticleList.vue'
import WriteArticle from '../views/ViewWriteArticle.vue'
import ViewTag from '../views/ViewTag.vue'
import ViewCategory from '../views/ViewCategory.vue'
import ViewDocument from '../views/ViewDocument.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/view/article',
    component: HomeView
  },
  {
    path: '/view',
    name: 'view',
    redirect: '/view/article',
    component: HomeView,
    children: [
      {
        path: 'article',
        name: 'article',
        component: ArticleList
      },
      {
        path: 'manageArticle',
        name: 'manageArticle',
        component: ManageArticle
      },
      {
        path: 'writeArticle',
        name: 'writeArticle',
        component: WriteArticle
      },
      {
        path: 'tag',
        name: 'tag',
        component: ViewTag
      },
      {
        path: 'category',
        name: 'category',
        component: ViewCategory
      },
      {
        path: 'document',
        name: 'document',
        component: ViewDocument
      },
      {
        path: 'detail',
        name: 'detail',
        component: ArticleDetail
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
