<template>
    <div class="detail">
        tag
    </div>
</template>

<script>

export default {
    name: 'ViewTag'
}
</script>

<style scoped>

</style>