<template>
    <div class="detail">
        category
    </div>
</template>

<script>

export default {
    name: 'ViewCategory'
}
</script>

<style scoped>

</style>