<template>
    <div class="detail">
        <div class="expand">
            <div class="header">
                <h1>{{ article.title }}</h1>
                <div class="post-meta">
                    <span>发表于 {{ article.addtime }}&nbsp;&nbsp;</span>&nbsp;
                    <!-- <span>更新于 {{ article.addtime }}&nbsp;&nbsp;</span>&nbsp; -->
                    <!-- <span>分类于 {{ article.category }}&nbsp;&nbsp;</span> -->
                </div>
            </div>
            <mavon-editor v-model="article.content" :ishljs="true" default-open="preview" :editable="false"
                :subfield="false" :toolbarsFlag="false" />
            <div class="footer">

            </div>
        </div>
    </div>
</template>

<script>
// import { Message } from "element-ui";
import "mavon-editor/dist/css/index.css"
import "mavon-editor/dist/markdown/github-markdown.min.css";
import hljs from "mavon-editor/dist/highlightjs/highlight.min.js";
import "mavon-editor/dist/highlightjs/styles/github-dark-dimmed.min.css";
import axios from "axios";
// import { queryArticle } from "../../api/home";

export default {
    name: 'ArticleDetail',
    data() {
        return {
            articleid: null,
            article: {}
        }
    },
    created() {
        //拿到传递的参数
        this.articleid = this.$route.query.articleid;
        //请求接口，获取详情
        axios.get('/lspace/bgArticle/detail',{params:{id:this.articleid}})
            .then(response => {
                // 处理响应
                this.article = response.data.data;
            })
            .catch(error => {
                // 处理错误
                console.error(error);
                this.$message.error('获取详情失败');
            });
    },
    mounted() {
        this.$nextTick(() => {
            clearInterval(this.timer);
            this.getCodes();
        });
    },
    methods: {
        // 为什么要这么写呢？
        // 是因为mavon-editor需要获取到katex.min.js等文件
        // 然后又是cdn获取的，所以有些时候请求慢
        // 导致dom先加载，然后才加载js、css这些mavon-editor所需要的文件
        // 因此需要写setInterval去等katex.min.js等文件加载完成
        // 这样才能获取到offsetHeight的问题。
        // 不然你可以试试去掉这段代码
        // 你的offsetHeight一直为0，没有代码块序号。
        getCodes() {
            this.codes = document.querySelectorAll("pre code");
            if (this.codes.length > 0) {
                for (let i = 0; i < this.codes.length; i++) {
                    if (this.codes[i].offsetHeight != 0) {
                        return this.init();
                    } else {
                        this.timer = setInterval(() => {
                            for (let j = 0; j < this.codes.length; j++) {
                                if (this.codes[j].offsetHeight != 0) {
                                    clearInterval(this.timer);
                                    return this.init();
                                }
                            }
                        }, 500);
                        return;
                    }
                }
                return;
            }
        },
        init() {
            this.$nextTick(() => {
                clearInterval(this.timer);
                this.codes.forEach((item) => {
                    // 取出 code 的父元素 pre（后面方便使用）
                    let pre = item.parentElement;
                    // 添加拖动属性
                    this.dragAround(item);
                    // 新建元素 代码块序号
                    // let lineNumBox = document.createElement("div");
                    // lineNumBox.setAttribute(
                    //     "style",
                    //     "height: " + item.offsetHeight + "px"
                    // );
                    // lineNumBox.className = "line-num-box";
                    // // 插入序号 计算方式：获取code元素的高并除以行高，得到行数，根据行数插入序号
                    // let num = ""; // 设行高二十
                    // for (let i = 1; i < Math.ceil(item.offsetHeight / 20) + 1; i++) {
                    //     num += i + "\n"; // 序号加上换行符
                    // }
                    // lineNumBox.innerText = num; // 插入序号
                    // pre.insertBefore(lineNumBox, item);
                    let codeBox = document.createElement("div");
                    codeBox.className = "code-box";
                    codeBox.appendChild(item);
                    pre.appendChild(codeBox);
                    // let icon =
                    //     `<div class="mac-icon">` +
                    //     `<span class="mac-icon-red"></span>` +
                    //     `<span class="mac-icon-yellow"></span>` +
                    //     `<span class="mac-icon-green"></span>` +
                    //     `<button class="copy-button">复制</button>` +
                    //     `</div>`;

                    // pre.insertAdjacentHTML("afterbegin", icon);
                    // 获取复制元素
                    // let copyButton =
                    //     pre.firstElementChild.getElementsByClassName("copy-button")[0];
                    // copyButton.onclick = function () {
                    //     const copyPromise = navigator.clipboard.writeText(
                    //         pre.lastElementChild.innerText
                    //     );
                    //     copyPromise
                    //         .then(() => {
                    //             Message({
                    //                 message: "copy成功",
                    //                 type: "success",
                    //                 duration: 0,
                    //             });
                    //         })
                    //         .catch(() => {
                    //             Message({
                    //                 message: "copy失败",
                    //                 type: "success",
                    //                 duration: 0,
                    //             });
                    //         });
                    // };
                    hljs.highlightBlock(codeBox.firstElementChild);
                });
            });
        },
        // 给某个元素添加左右拖动属性
        dragAround(anyElement) {
            let mouseDown = false; // 鼠标是否按下
            let x = 0; // 鼠标点击的下标
            let left = 0; // 当前滚动条位置
            anyElement.onmousedown = function (e) {
                mouseDown = true; // 鼠标按下
                x = e.clientX; // 获取鼠标点击位置 （x坐标）
                left = this.scrollLeft; // 滚动条当前位置
            };
            anyElement.onmousemove = function (e) {
                // 鼠标按下
                if (mouseDown) {
                    let curX = e.clientX; // 鼠标移动到当前的位置
                    let diffX = curX - x; // 鼠标移动距离（当前位置 减去之前的位置）
                    this.scrollLeft = left - diffX;
                }
            };
            anyElement.onmouseup = function () {
                mouseDown = false;
            };
            anyElement.onmouseleave = function () {
                mouseDown = false;
            };
        },
    },
    destroyed() {
        clearInterval(this.timer);
    }
}
</script>

<style scoped>
.body {
    text-align: left;
}

.detail {
    width: 78%;
    margin-left: 1%;
    /* height: 900px; */
    /* margin-left: 10%; */
    /* height: 100%; */
    /* background-color: antiquewhite; */
    float: left;
}

.expand {
    width: 100%;
    /* margin-top: 10px; */
    /* height: 900px; */
    /* margin-left: 3%; */
    background-color: #FFFFFF;
}

.markdown-body pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #FFFFFF;
    border-radius: 3px;
}

h1 {
    margin-top: 0px;
}

.header {
    padding-top: 50px;
    /* background-color: aquamarine; */
}

.body {
    /* background-color: blueviolet; */
}

.footer {
    /* background-color: chocolate; */
}

.post-meta {
    height: 27px;
    margin: 3px 0px 60px;
    /* background-color:burlywood; */
}

.post-meta span:first-child {
    color: #999999;
    font-size: 13.5px;
    /* border-right: 1px solid #999; */
}

/* .post-meta span:nth-child(2) {
    color: #999999;
    font-size: 13.5px;
    border-right: 1px solid #999;
}

.post-meta span:last-child {
    color: #999999;
    font-size: 13.5px;
} */

pre {
    opacity: 1 !important;
    border: 1px solid #272822 !important;
    background-color: #272822 !important;
    border-radius: 15px !important;
}

.line-num-box {
    display: inline-block !important;
    color: #ccc !important;
    border-right: 2px solid #fff !important;
    line-height: 20px !important;
    font-size: 16px !important;
    text-align: right !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.code-box {
    display: inline-block !important;
    vertical-align: top !important;
    width: calc(100% - 50px) !important;
    border-left-style: none !important;
}

code {
    line-height: 20px !important;
    font-size: 16px !important;
    vertical-align: top;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 10px !important;
}

code::-webkit-scrollbar {
    height: 10px !important;
    border-radius: 5px !important;
    background-color: #1e1e1e !important;
}

code::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px !important;
    background-color: #666 !important;
}

code::-webkit-scrollbar-button {
    /*滚动条的轨道的两端按钮，允许通过点击微调小方块的位置*/
    border-radius: 5px !important;
    background-color: #1e1e1e !important;
}

.mac-icon {
    height: 30px !important;
    border-bottom: 1px solid silver !important;
    margin-bottom: 5px !important;
    color: deeppink !important;
}

.mac-icon>span {
    display: inline-block !important;
    letter-spacing: 5px !important;
    word-spacing: 5px !important;
    width: 16px !important;
    height: 16px !important;
    border-radius: 8px !important;
}

.mac-icon-red {
    background-color: red !important;
}

.mac-icon-yellow {
    margin-left: 10px;
    background-color: yellow !important;
}

.mac-icon-green {
    margin-left: 10px;
    background-color: green !important;
}

.mac-icon-lang {
    width: 50px !important;
    padding-left: 10px !important;
    font-size: 16px !important;
    vertical-align: top !important;
}

.copy-button {
    padding: 2px 8px !important;
    color: #ffffff !important;
    background-color: #9999aa !important;
    margin-bottom: 5px !important;
    border-radius: 5px !important;
    outline: none !important;
    border: none !important;
}

.copy-button {
    float: right !important;
}

.copy-button:hover {
    cursor: pointer !important;
    background-color: black !important;
}

.markdown-body .lang- {
    display: block;
    overflow: auto;
    font-size: 16px !important;
    background: #272822 !important;
    color: #fff;
}

.markdown-body .hljs {
    display: block;
    overflow: auto;
    font-size: 16px !important;
    background: #272822 !important;
    color: #fff;
}

.hljs,
.hljs-tag,
.hljs-subst {
    color: #f8f8f2 !important;
}

.hljs-strong,
.hljs-emphasis {
    color: #a8a8a2 !important;
}

.hljs-bullet,
.hljs-quote,
.hljs-number,
.hljs-regexp,
.hljs-literal,
.hljs-link {
    color: #ae81ff !important;
}

.hljs-code,
.hljs-title,
.hljs-section,
.hljs-selector-class {
    color: #a6e22e !important;
}

.hljs-strong {
    font-weight: bold;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-name,
.hljs-attr {
    color: #f92672 !important;
}

.hljs-symbol,
.hljs-attribute {
    color: #66d9ef !important;
}

.hljs-params,
.hljs-class .hljs-title {
    color: #f8f8f2 !important;
}

.hljs-string,
.hljs-type,
.hljs-built_in,
.hljs-builtin-name,
.hljs-selector-id,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-addition,
.hljs-variable,
.hljs-template-variable {
    color: #e6db74 !important;
}

.hljs-comment,
.hljs-deletion,
.hljs-meta {
    color: #75715e !important;
}
</style>