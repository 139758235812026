<template>
    <div class="home">
        <WebHeader></WebHeader>
        <div class="container">
            <WebNav></WebNav>
            <router-view />
        </div>
        <WebTail></WebTail>
    </div>
</template>

<script>
import WebNav from '@/components/Nav.vue'
import WebHeader from '@/components/Header.vue'
import WebTail from '@/components/Tail.vue'


export default {
    name: 'HomeView',
    components: {
        WebNav,
        WebHeader,
        WebTail,
    }
}
</script>

<style scoped>
.home {
    width: 100%;
    /* height: 900px; */
    background-color: #F5F7F9;
}

.container {
    width: 85%;
    /* height: 100%; */
    margin-left: 7%;    
    overflow: hidden;
    /* background-color: aquamarine; */
}
</style>