<template>
    <div class="left">
        <div class="flex">
            <div class="show">anony的博客</div>
            <div class="menu">
                <ul>
                    <li>
                        <a href="">
                            <router-link to="/view/article">首页</router-link>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <router-link to="/view/manageArticle">写文章</router-link>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <router-link to="/view/tag">标签</router-link>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <router-link to="/view/category">分类</router-link>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <router-link to="/view/document">归档</router-link>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="fix">
            <div class="userinfo">
                <img src="@/assets/avatar.jpg" height="120px" width="120px">
                <div class="author">anony</div>
                <div class="desc">Life is a wilderness,not a track.</div>
            </div>
            <div class="state">
                <div class="state-pos">
                    <span class="state-count">{{ articleNums }}</span><br>
                    <span class="state-item">日志</span>
                </div>
                <div class="state-ca">
                    <span class="state-count">0</span><br>
                    <span class="state-item">分类</span>
                </div>
                <div class="state-ta">
                    <span class="state-count">0</span><br>
                    <span class="state-item">标签</span>
                </div>
            </div>
            <div class="link">
                <div class="git"><a>Github</a></div>
                <div class="mail"><a>E-Mail</a></div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'WebNav',
    data() {
        return {
            articleNums: 0
        }
    },
    //监听函数
    watch: {
        '$route'() {
            this.getData();
        }
    },
    created() {
        //请求接口，获取详情
        axios.get('/lspace/bgArticle/getCount')
            .then(response => {
                // 处理响应
                console.log(response.data);
                this.articleNums = response.data.data;
            })
            .catch(error => {
                // 处理错误
                console.error(error);
                this.$message.error('获取详情失败');
            });
    },
    methods: {
        getData() {
            //请求接口，获取详情
            axios.get('/lspace/bgArticle/getCount')
                .then(response => {
                    // 处理响应
                    console.log(response.data);
                    this.articleNums = response.data.data;
                })
                .catch(error => {
                    // 处理错误
                    console.error(error);
                    this.$message.error('获取数量失败');
                });
        }
    }
}
</script>

<style scoped>
.left {
    width: 21%;
    /* height: 100%; */
    background-color: #F5F7F9;
    /* background-color: #FFFFFF; */
    float: left;
}

.flex {
    height: 294px;
    background-color: #FFFFFF;
}


.show {
    height: 78px;
    background-color: #222222;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: normal;
    line-height: 78px;
}

.menu {
    height: 216px;
    /* background-color: blueviolet; */
}

ul {
    padding: 0;
}

ul li {
    height: 36px;
    list-style-type: none;
    /* background-color: antiquewhite; */
}

ul li a {
    height: 36px;
    display: block;
    padding-top: 5px;
    padding-left: 20px;
    text-decoration: none;
    text-align: left;
    font-size: 13px;
    color: #555555;
    line-height: 36px;
}

ul li a:hover {
    background-color: #F5F5F5;
}


.fix {
    height: 315px;
    margin-top: 15px;
    background-color: #FFFFFF;
}

img {
    margin-top: 30px;
}

/* .userinfo {
    height: 183px;
} */

.author {
    height: 32px;
    color: #222222;
    font-size: 16px;
    font-weight: 600;
}

.desc {
    height: 26px;
    color: #999999;
    font-size: 13px;
}

.state {
    height: 45px;
    color: #555555;
    font-size: 16px;
    /* background-color: aquamarine; */
}

.state-item {
    color: #999999;
    font-size: 13px;
}

.state-count {
    color: #222222;
    font-size: 16px;
}

.state-pos {
    height: 90%;
    padding-top: 4px;
    width: 46px;
    color: #999999;
    font-size: 13px;
    float: left;
    margin-left: 20%;
    border-right: 1px solid #EEEEEE;
}

.state-ca {
    height: 90%;
    padding-top: 4px;
    width: 46px;
    color: #999999;
    font-size: 13px;
    float: left;
    border-right: 1px solid #EEEEEE;
}

.state-ta {
    height: 90%;
    padding-top: 4px;
    width: 46px;
    color: #999999;
    font-size: 13px;
    float: left;
}

.link {
    height: 26px;
    margin-top: 5px;
    /* background-color: bisque; */
}

.link a:hover {
    color: #eee;
}

.git {
    height: 26px;
    width: 50%;
    color: #555555;
    font-size: 13px;
    /* background-color: yellowgreen; */
    float: left;
    line-height: 26px;
}

.mail {
    height: 26px;
    width: 50%;
    color: #555555;
    font-size: 13px;
    /* background-color:#999999; */
    float: left;
    line-height: 26px;
}
</style>