<template>
    <div class="tail">
        <div class="tip">
            © 2020  anony <br>
            由 Vue 强力驱动  | 主题 – NexT.Pisces v7.7.0
        </div>
    </div>
</template>

<script>

export default {
    name: 'WebTail'
}
</script>

<style scoped>
.tail {
    height: 80px;
    width: 100%;
    /* background-color: #F5F7F9; */
    background-color: #2B343D;
}

.tip {
    height: 40px;
    width: 500px;
    /* background-color: aqua; */
    margin-left: 32%;
    padding-top: 20px;
    color: #696E74;
}

</style>