<template>
    <div class="right">
        <div class="search">
            <el-input placeholder="请输入标题" v-model="inputStr" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="searchArticle(inputStr)"></el-button>
            </el-input>
        </div>
        <div v-for="article in articles" :key="article.id">
            <div class="article-info">
                <div class="post-title">
                    <!-- <a href="" >{{ article.title }}</a> -->
                    <a href="" v-html="article.title"></a>
                </div>

                <div class="post-meta">
                    <span>发表于 {{ article.addtime }}&nbsp;&nbsp;</span>&nbsp;
                    <!-- <span>更新于 {{ article.updatetime }}&nbsp;&nbsp;</span>&nbsp; -->
                    <!-- <span>分类于 {{ article.category }}&nbsp;&nbsp;</span> -->
                </div>

                <div class="post-body">
                    <p>{{ article.description }}</p>
                    <div class="post-button">
                        <a href="" @click="getDetail(article.id)">阅读全文 »</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagemain">
            <div class="pagesub">
                <!--分页-->
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'ArticleList',
    data() {
        return {
            inputStr: '',
            pageNum: 1,
            pageSize: 10,
            currentPage: 1,
            total: 0,
            articles: []
        }
    },
    created() {
        //请求接口，获取详情
        axios.get('/lspace/bgArticle/getPageList',
            {
                params: {
                    pageNo: 1,
                    pageSize: 10
                }
            })
            .then(response => {
                // 处理响应
                console.log(response.data);
                this.total = response.data.total;
                this.articles = response.data.records;
            })
            .catch(error => {
                // 处理错误
                console.error(error);
                this.$message.error('获取详情失败');
            });
    },
    methods: {
        searchArticle(title) {
            axios.get('/lspace/bgArticle/getPageList',
                {
                    params: {
                        pageNo: 1,
                        pageSize: 10,
                        title: title
                    }
                })
                .then(response => {
                    console.log(response.data);
                    this.articles = response.data.records;
                    this.total = response.data.total;
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error('获取文章失败');
                });
        },
        getDetail(id) {
            this.$router.push({
                name: 'detail',
                query: {
                    articleid: id
                }
            })
        },
        //监听 pageSize 改变事件
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getData()
        },
        //监听页码改变的事件
        handleCurrentChange(newPage) {
            this.pageNum = newPage
            this.getData()
        }
    }
}
</script>

<style scoped>
.right {
    width: 78%;
    /* height: 100%; */
    background-color: #FFFFFF;
    /* background-color:cadetblue; */
    float: left;
    margin-left: 1%;
}

.search {
    width: 400px;
    height: 50px;
    margin-left: 30%;
    margin-top: 20px;
}

.article-info {
    height: 324px;
    width: 90%;
    /* background-color: aquamarine; */
    margin-left: 5%;
}

.post-title {
    height: 40px;
    margin-top: 25px;
    /* background-color:bisque; */
}

.post-title a {
    font-size: 27px;
    text-decoration: none;
    color: #555555;
}

.post-meta {
    height: 27px;
    margin: 3px 0px 60px;
    /* background-color:burlywood; */
}

.post-meta span:first-child {
    color: #999999;
    font-size: 13.5px;
    /* border-right: 1px solid #999; */
}

/*.post-meta span:nth-child(2) {
    color: #999999;
    font-size: 13.5px;
    border-right: 1px solid #999;
}

.post-meta span:last-child {
    color: #999999;
    font-size: 13.5px;
}*/

/* .post-meta span {
    color: #999999;
    font-size: 13.5px;
    border-right: 1px solid #999;
} */


.post-body {
    height: 112px;
    /* background-color:cadetblue; */
    /* 在悬停时改变背景颜色 */
}

.post-body p {
    color: #555555;
    font-size: 18px;
    text-align: left;
}

.post-button {
    margin-top: 45px;
    text-align: center;
}

.post-button a {
    background: #fff;
    border: 2px solid #555;
    border-radius: 2px;
    color: #555;
    display: inline-block;
    font-size: 16px;
    line-height: 2;
    padding: 0 20px;
    text-decoration: none;
    transition-property: background-color;
    transition-delay: 0s;
    transition-timing-function: ease-in-out;
}

a {
    text-decoration: none;
    /* color: #fff; */
}

.router-link-active {
    text-decoration: none;
}

.post-button a:hover {
    background: #222;
    border-color: #222;
    color: #fff;
}

.pagemain {
    width: 100%;
    height: 80px;
    /* background-color: aqua; */
}

.pagesub {
    width: 40%;
    height: 50px;
    /* background-color: burlywood; */
    margin-left: 57%;
    margin-top: 15px;
    float: left;
}

.el-pagination {
    width: 100%;
    height: 100%;
}
</style>