<template>
    <div class="detail">
        <div class="container">
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item label="文章标题" prop="title">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="文章描述" prop="description">
                    <el-input v-model="form.description"></el-input>
                </el-form-item>

                <el-form-item label="文章正文" prop="content">
                    <br>
                    <mavon-editor class="editor" @change="change" v-model="form.mdcontent" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'WriteArticle',
    data() {
        return {
            content: "",
            html: "",
            configs: {},
            form: {
                id: '',
                title: '',
                description: '',
                content: '',
                mdcontent: ''
            },
            rules: {
                title: [
                    { required: true, message: '请输入文章名称', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: '请输入文章描述', trigger: 'blur' }
                ]
            }
        }
    },
    created() {
        if (this.$route.query.articleid) {
            this.form.id = this.$route.query.articleid;
            if (this.form.id) {
                axios.get('/lspace/bgArticle/detail', { params: { id: this.form.id } })
                    .then(response => {
                        // 处理响应
                        this.form = response.data.data;
                    })
                    .catch(error => {
                        // 处理错误
                        console.error(error);
                        this.$message.error('获取详情失败');
                    });
            }
        }
    },
    methods: {
        change(value, render) {
            // render 为 markdown 解析后的结果
            this.form.content = render;
            this.form.mdcontent = value;
        },
        onSubmit() {
            // console.log(this.content);
            // console.log(this.html);
            // this.$message.success("提交成功！");
            const formData = new FormData();
            formData.append('id', this.form.id);
            formData.append('title', this.form.title);
            formData.append('description', this.form.description);
            formData.append('content', this.form.content);
            formData.append('mdcontent', this.form.mdcontent);
            this.$confirm('是否提交文章?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios.post('/lspace/bgArticle/add', formData)
                    .then(response => {
                        // 处理响应
                        console.log(response.data);
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        this.$router.push({
                            name: 'home'
                        })
                    })
                    .catch(error => {
                        // 处理错误
                        console.error(error);
                        this.$message.error('提交失败');
                    });
                // axios({
                //     url: '/lspace/bgArticle/add',
                //     method: 'POST',
                //     data: {
                //         formData
                //     }
                // })
                // this.$message({
                //     type: 'success',
                //     message: '提交成功!'
                // });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消提交'
                });
            });
        }
    }
}
</script>

<style scoped>
.detail {
    width: 78%;
    /* height: 100%; */
    /* background-color: #FFFFFF; */
    /* background-color:cadetblue; */
    float: left;
    margin-left: 1%;
    background-color: #FFFFFF;
}

/* .editor-btn {
        margin-top: 20px;
    } */

.title {
    padding-bottom: 30px;
    text-align: center;
    font-size: 20px;
    letter-spacing: 1px;
    color: #333;
    font-weight: 600;
}

.editor-btn {
    margin-top: 15px;
    margin-bottom: 10px;
}

.editor {
    height: 800px;
}
</style>